<template>
  <div>
    <download ref="download"></download>
    <img src="./../../assets/img/cardPage/4545454555555.png" alt="" />
  </div>
</template>

<script>
import download from "../../components/download.vue";
export default {
  components: { download }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
